import React from 'react';

import Container from '../Container/Container';

import './PublicOffer.scss';

const PublicOffer: React.FC = () => {
  return (
    <div className="public-offer">
      <Container>
        <h1>Договір публічної оферти</h1>
        <p>
          Цей договір між ФОП Агріч Олег В&rsquo;ячеславович в подальшому «Продавець» і користувачем послугами інтернет-сайту, в подальшому
          - «Замовник», є договором доручення на замовлення, придбання та доставку Товарів і визначає основні умови замовлення, придбання та
          доставки товарів через інтернет сайт https://cogu.com.ua. Покупець, діючи з метою придбання Товару, приймає умови цього договору
          купівлі-продажу товарів (далі - Договір) на наступних умовах.
        </p>
        <h2>1. ВИЗНАЧЕННЯ ТЕРМІНІВ</h2>
        <p>
          1.1. Публічна оферта (далі - «Оферта») - публічна пропозиція Продавця, адресована невизначеному колу осіб, укласти з Продавцем
          договір купівлі-продажу товару дистанційним способом (далі - «Договір») на умовах, що містяться в цій Оферті, включаючи всі
          Додатки.
          <br />
          <br />
          1.2. Замовлення - рішення Замовника замовити товар і його доставку, оформлене в інтернет-магазині і / або доручення на придбання і
          доставку товарів.
        </p>
        <h2>2. ЗАГАЛЬНІ ПОЛОЖЕННЯ</h2>
        <p>
          2.1. Наведена нижче інформація є офіційною пропозицією (офертою) інтернет-магазину https://cogu.com.ua будь-якій фізичній особі
          (далі - Покупець) укласти договір купівлі-продажу товарів. Зазначений договір є публічним, тобто. Е. Відповідно до статті 633
          Цивільного кодексу України його умови однакові для всіх покупців.
          <br />
          <br />
          2.2. Згідно зі статтею 642 Цивільного Кодексу України повним і беззастережним прийняттям умов даної пропозиції (оферти), що
          підтверджує укладення Договору купівлі-продажу товарів на запропонованих нижче умовах, є факт оформлення та підтвердження
          замовлення.
          <br />
          <br />
          2.3. Оформленням Замовлення Покупець підтверджує узгодження і безумовне прийняття ним умов цього пропозиції (оферти).
          <br />
          <br />
          2.4. Укладаючи Договір (тобто акцептуючи умови справжнього Пропозиції (Пропоновані можливості) шляхом оформлення Замовлення),
          Покупець підтверджує наступне:
          <br />
          <br />
          <b>
            Покупець цілком і повністю ознайомлений, і згоден з умовами цієї пропозиції (оферти);
            <br />
            <br />
            Він дає дозвіл на збір, обробку та передачу персональних даних на умовах, визначених нижче в Застереженні щодо збору, обробки і
            передачі персональних даних дозвіл на обробку персональних даних діє протягом усього терміну дії Договору, а також протягом
            необмеженого терміну після закінчення його дії. Крім цього, укладенням Договору Замовник підтверджує, що він повідомлений (без
            додаткового повідомлення) про права, встановлених Законом України "Про захист персональних даних", про цілі збору даних, а також
            про те, що його персональні дані передаються Продавцю з метою можливості виконання умов цього Договору, можливості проведення
            взаєморозрахунків, а також для отримання рахунків, актів та інших документів. Замовник також погоджується з тим, що Продавець
            має право надавати доступ та передавати його персональні дані третім особам без будь-яких додаткових повідомлень Замовника, не
            змінюючи при цьому мета обробки персональних даних. Обсяг прав Замовника, як суб&rsquo;єкта персональних даних відповідно до
            Закону України &rsquo;Про захист персональних даних&rsquo; йому відомий і зрозумілий.
          </b>
        </p>
        <h2>3. ЦІНА ТОВАРУ</h2>
        <p>
          3.1. Ціна на кожну позицію Товару вказана на сайті Інтернет-магазину.
          <br />
          <br />
          3.2. Продавець має право в односторонньому порядку змінити ціну на будь-яку позицію Товару.
          <br />
          <br />
          3.3. У разі зміни ціни на замовлений Товар Продавець зобов&rsquo;язується проінформувати Покупця про зміну ціни Товару.
          <br />
          <br />
          3.4. Покупець має право підтвердити або анулювати Замовлення на придбання Товару, якщо ціна змінена Продавцем після оформлення
          Замовлення.
          <br />
          <br />
          3.5. Зміна Продавцем ціни на оплачений Покупцем Товар не допускається.
          <br />
          <br />
          3.6. Продавець вказує вартість доставки Товару на сайті Інтернет-магазину або повідомляє Покупцеві при оформленні замовлення
          Оператором.
          <br />
          <br />
          3.7. Зобов&rsquo;язання Покупця по оплаті Товару вважаються виконаними з моменту надходження Продавцю коштів.
          <br />
          <br />
          3.8. Розрахунки між Продавцем і Покупцем за Товар здійснюються способами, зазначеними на сайті Інтернет-магазину в розділах
          «Доставка» і «Оплата».
        </p>
        <h2>4. ОФОРМЛЕННЯ ЗАМОВЛЕННЯ</h2>
        <p>
          4.1. Замовлення Товару здійснюється Покупцем через Оператора по телефону:{' '}
          <span className="binct-phone-number-3">+380637300303</span> або через сервіс сайту Інтернет-магазину{' '}
          <a href="https://cogu.com.ua">https://cogu.com.ua</a> <br />
          <br />
          4.2. При реєстрації на сайті Інтернет-магазину Покупець зобов&rsquo;язується надати наступну реєстраційну інформацію:
          <br />
          <br />
          4.2.1. Прізвище та ім&rsquo;я Покупця або вказаної ним особи (одержувача);
          <br />
          <br />
          4.2.2. Адреса, за якою слід доставити Товар (якщо доставка до адреси Покупця);
          <br />
          <br />
          4.2.3. Адреса електронної пошти (не обов&rsquo;язкове поле для заповнення);
          <br />
          <br />
          4.2.4. Контактний телефон.
          <br />
          <br />
          4.3. Найменування, кількість, артикул, ціна обраного Покупцем Товару вказуються в кошику Покупця на сайті Інтернет-магазину.
          <br />
          <br />
          4.4. Якщо Продавцю необхідна додаткова інформація, він має право запросити її у Покупця. У разі ненадання необхідної інформації
          Покупцем, Продавець не несе відповідальності за надання якісної послуги Покупцеві при покупці товарів інтернет-магазині.
          <br />
          <br />
          4.5. При оформленні Замовлення через Оператора (п. 4.1. Цієї Оферти) Покупець зобов'язується надати інформацію, зазначену в п.
          4.2. цієї Оферти.
          <br />
          <br />
          4.6. Ухвалення Покупцем умов цієї Оферти здійснюється за допомогою внесення Покупцем відповідних даних в реєстраційну форму на
          сайті Інтернет-магазину або при оформленні Замовлення через Оператора. Після оформлення Замовлення через Оператора дані про
          Покупця реєструються в базі даних Продавця.
          <br />
          <br />
          4.7. Покупець несе відповідальність за достовірність наданої інформації при оформленні Замовлення.
          <br />
          <br />
          4.8. Договір купівлі-продажу дистанційним способом між Продавцем і Покупцем вважається укладеним з моменту електронного оформлення
          замовлення на сервісі сайту інтернет-магазину або видачі Продавцем Покупцеві касового або товарного чека або іншого документа, що
          підтверджує оплату Товару.
        </p>
        <h2>5. ДОСТАВКА І ПЕРЕДАЧА ТОВАРУ ПОКУПЦЮ</h2>
        <p>
          5.1. Способи, порядок і терміни доставки товарів вказані на сайті в розділі «Доставка». Порядок і умови доставки замовленого
          товару Покупець погоджує з оператором інтернет-магазину в момент оформлення покупки.
          <br />
          <br />
          5.2. Доставка товару здійснюється власними силами співробітниками інтернет-магазину відповідно до умов доставки, або із залученням
          третіх осіб (перевізника).
          <br />
          <br />
          5.3. При отриманні товару Замовник повинен у присутності представника кур'єра перевірити відповідність Товару якісним і кількісним
          характеристикам, (найменування товару, кількість, комплектність, термін придатності).
          <br />
          <br />
          5.4. Замовник або Представник Замовника під час приймання товару підтверджує своїм підписом в товарному чеку і / або замовленні на
          доставку товарів, що не має претензій до кількості товару, зовнішнім виглядом і комплектності товару.
        </p>
        <h2>6. ПОВЕРНЕННЯ ТОВАРУ</h2>
        <p>
          6.1. Замовник має право відмовитися від товару в будь-який час до його передачі, а після передачі товару - в порядку і на умовах,
          визначених Законом України «Про захист прав споживачів».
          <br />
          <br />
          6.2. Повернення підакцизного товару належної якості можливий у випадку, якщо збережено його товарний вигляд, споживчі властивості,
          а також документ, що підтверджує факт покупки і умови замовлення зазначеного товару.
          <br />
          <br />
          6.3. Замовник не має права відмовитися від товару належної якості, що має індивідуально-визначені властивості, якщо зазначений
          товар може бути використаний виключно Споживачем, який його придбав, (в т.ч. не стандартні, за бажанням Замовника, розміри та
          інше). Підтвердженням того, що товар має індивідуально-визначені властивості, є відмінність розмірів товару та інших
          характеристик, що вказані в інтернет-магазині.
          <br />
          <br />
          6.4. Повернення товару, у випадках, передбачених законом та цим Договором, здійснюється за адресою, вказаною на сайті в розділі
          «Доставка».
          <br />
          <br />
          6.5. При відмові Покуптеля від товару належної якості Продавець повертає кошти в розмірі вартості такого Товару, за винятком
          витрат продавця на доставку товару, який повертається.
          <br />
          <br />
          6.6. Повернення суми, зазначеної в п.6.5. здійснюється протягом 7 робочих днів після отримання повернення магазином товару.
        </p>
        <h2>7. ВІДПОВІДАЛЬНІСТЬ СТОРІН</h2>
        <p>
          7.1. Продавець не несе відповідальності за шкоду, заподіяну Покупцеві внаслідок неналежного використання Товарів, попередньо
          замовлених на сайті https://cogu.com.ua і придбаних у Продавця.
          <br />
          <br />
          7.2. Продавець не несе відповідальності за неналежне, несвоєчасне виконання Замовлень і своїх зобов'язань в разі надання Покупцем
          недостовірної або помилкової інформації.
          <br />
          <br />
          7.3. Продавець і Покупець несуть відповідальність за виконання своїх зобов'язань відповідно до чинного законодавства України і
          положень цього Договору.
          <br />
          <br />
          7.4. Продавець або Покупець звільняються від відповідальності за повне або часткове невиконання своїх зобов'язань, якщо
          невиконання є наслідком форс-мажорних обставин як: війна або військові дії, землетрус, повінь, пожежа та інші стихійні лиха, що
          виникли незалежно від волі Продавця і / або Покупця після укладення цього договору. Сторона, яка не може виконати свої
          зобов'язання, негайно повідомляє про це іншу Сторону.
        </p>
        <h2>АДРЕСА І РЕКВІЗИТИ</h2>
        <p>
          <br />
          <br />
          Адреса: м. Одеса, вул. Савранська, 3 <br />
          <br />
          Код: 3201207971
          <br />
          <br />
          Назва банку: PrivatBank
          <br />
          <br />
          Рахунок у форматі IBAN: UA283052990000026003044919425
        </p>
      </Container>
      <div className="blue-circle" />
      <div className="yellow-circle" />
      {/* <div className="blue-circle blue-circle-2" />
      <div className="yellow-circle yellow-circle-2" /> */}
    </div>
  );
};

export default PublicOffer;
