import React from 'react';

import Seo from '../components/SEO/SEO';

import { SEO_ITEMS } from '../constants/SEOItems';
import PublicOffer from '../components/PublicOffer/PublicOffer';
import Subheader from '../components/Subheader/Subheader';

const crumbs = [
  {
    title: 'Головна',
    link: '/',
  },
  {
    title: 'Договір публічної оферти',
    link: '/public-offer',
  },
];

const publicOfferPage: React.FC = (): JSX.Element => {
  return (
    <>
      <Seo
        breadcrumbs={crumbs}
        description={SEO_ITEMS.publicOfferPage.description}
        lang="ua"
        path="/public-offer"
        title={SEO_ITEMS.publicOfferPage.title}
      />
      <Subheader crumbs={crumbs} withContainer />
      <PublicOffer />
    </>
  );
};

export default publicOfferPage;
